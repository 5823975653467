import React from "react";
import { Card, Spinner, Text } from "@shopify/polaris";
import { DarkCardContainer, SpinnerStack } from "../../../libs";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";

import { useCustomerSubscriptionOrderCount } from "../../libs/hooks/useCustomerSubscriptionOrderCount";
import { useShopifyCustomerData } from "../../libs/hooks/useShopifyCustomerData";

interface CustomerSpendAndOrdersProps {
  customerShopifyId: string;
}

export const CustomerSpendAndOrders = ({ customerShopifyId }: CustomerSpendAndOrdersProps) => {
  const { isShopifyCustomerDataLoading, shopifyCustomerData } = useShopifyCustomerData(customerShopifyId);
  const { orderCount, isLoading: isOrderCountLoading } = useCustomerSubscriptionOrderCount(customerShopifyId);

  if (isShopifyCustomerDataLoading || isOrderCountLoading) {
    return (
      <SpinnerStack>
        <Spinner />
      </SpinnerStack>
    );
  }

  if (!customerShopifyId || !shopifyCustomerData) {
    return <React.Fragment />;
  }

  return (
    <DarkCardContainer>
      <Card>
        <Text variant="headingMd" as="h2">
          Lifetime spend
        </Text>
        <Text variant="headingLg" as="p">
          {formatMoney(
            shopifyCustomerData.amountSpent.amount * 100,
            shopifyCustomerData.amountSpent.currencyCode
          )}
        </Text>
      </Card>
      <Card>
        <Text variant="headingMd" as="h2">
          Total subscription orders
        </Text>
        <Text variant="headingLg" as="p">
          {orderCount}
        </Text>
      </Card>
    </DarkCardContainer>
  );
};
