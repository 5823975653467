import { sortTiersByRank } from "../../utils/sortBy";
import { LoyaltyApi } from "./api";

/**
 * Determines a user's loyalty tier based on their current and previous year's earned points.
 *
 * orgTiers - List of orgs tiers
 * tieredPoints - The points earned by customer in the current year.
 * previousYearTieredPoints - The points earned by customer in the previous year.
 *
 * @returns
 *  - `previousTier`: The next tier in rank below the `currentYearTier`, or `null` if the user is at the lowest tier.
 *  - `previousYearTier`: The tier determined based on the greater number of points between last year and current year
 *  - `currentYearTier`: The tier based on points earned in the current year.
 *  - `nextTier`: The next tier in rank above the `currentYearTier`, or `null` if the user is at the highest tier.
 */
export const getUsersTierFromTieredPoints = ({
  orgTiers,
  tieredPoints,
  previousYearTieredPoints,
}: {
  orgTiers: LoyaltyApi.Tier.Type[];
  tieredPoints: number | null | undefined;
  previousYearTieredPoints?: number;
}): {
  previousTier: LoyaltyApi.Tier.Type | null;
  previousYearTier: LoyaltyApi.Tier.Type | null;
  currentYearTier: LoyaltyApi.Tier.Type | null;
  nextTier: LoyaltyApi.Tier.Type | null;
} => {
  if (!orgTiers.length) {
    return { previousTier: null, previousYearTier: null, currentYearTier: null, nextTier: null };
  }
  const sortedTiers = sortTiersByRank(orgTiers);

  if (!tieredPoints) {
    tieredPoints = 0;
  }

  // Current tier is determined by previous year's point amount or current year's point amount, whichever is greater
  const pointsToReference =
    previousYearTieredPoints && previousYearTieredPoints > tieredPoints ? previousYearTieredPoints : tieredPoints;

  let previousYearTier = sortedTiers[0];
  let currentYearTier = sortedTiers[0];

  for (const tier of sortedTiers) {
    if (pointsToReference >= tier.minimumPoints) {
      previousYearTier = tier;
    }

    if (tieredPoints >= tier.minimumPoints) {
      currentYearTier = tier;
    }
  }

  const currentYearTierIndex = sortedTiers.indexOf(currentYearTier);

  const previousTier = sortedTiers[currentYearTierIndex - 1] ?? null;

  return {
    previousTier,
    previousYearTier,
    currentYearTier,
    nextTier: sortedTiers[currentYearTierIndex + 1] ?? null,
  };
};
