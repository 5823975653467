import { useEffect, useState } from "react";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";
import { ICustomerRelationshipShallow } from "@smartrr/shared/entities/CustomerRelationship";
import { ISmartrrOrderFromDb } from "@smartrr/shared/entities/Order";

export const useCustomerSubscriptionOrderCount = (
  customerShopifyId: string
): { orderCount: number; isLoading: boolean } => {
  const [orderCount, setOrderCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const customer = useSmartrrVendorSelector(state =>
    state.customerRelationships.data.find(
      custRel => custRel.shopifyId === ensureShopifyGid("Customer", customerShopifyId)
    )
  );

  useEffect(() => {
    const fetchOrders = async (custRel: ICustomerRelationshipShallow): Promise<ISmartrrOrderFromDb[]> => {
      /**
       * Setting local variables to
       * handle pagination
       */
      let allOrders: ISmartrrOrderFromDb[] = [];
      let pageNumber = 0;
      let totalPages = 0;
      try {
        setIsLoading(true);
        do {
          const res = await typedFrontendVendorApi.getReq("/customer/:custRelId/orders", {
            params: {
              custRelId: custRel.id,
            },
            query: {
              pageNumber,
            },
          });

          if (res.type === "success") {
            const { data: orders, totalPages: fetchedTotalPages } = res.body;
            /**
             * We only want orders with subscription income,
             * therefore we're checking for the existence of
             * a purchase state. sts is an empty array for otp
             * orders.
             */
            const filteredSubscriptionOrders = orders.filter((order: ISmartrrOrderFromDb) => order.sts.length);
            allOrders = [...allOrders, ...filteredSubscriptionOrders];
            totalPages = fetchedTotalPages;
            pageNumber++;
          }
          // pageNumber is 0 indexed while totalPages is not
        } while (pageNumber <= totalPages - 1);
        setIsLoading(false);
      } catch (error) {
        /**
         * Not adding toast here as we're moving away from
         * error toasts as per Built For Shopify Criteria
         * TODO: Update with new FE error handling
         */
        console.error("Error fetching orders:", error);
      }
      return allOrders;
    };
    if (customer) {
      fetchOrders(customer)
        .then(allOrders => {
          setOrderCount(allOrders.length);
        })
        .catch(error => {
          console.error("Error getting orders:", error);
        });
    }
  }, [customer]);
  return { orderCount, isLoading };
};
