import { useEffect, useState } from "react";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { IRewardEvent } from "@vendor-app/app/_sharedComponents/Events/libs";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { ensureShopifyGid } from "@smartrr/shared/utils/ensureShopifyGid";

export const useCustomerRewardEvents = (customerShopifyId: string): IRewardEvent[] => {
  const customer = useSmartrrVendorSelector(state =>
    state.customerRelationships.data.find(
      custRel => custRel.shopifyId === ensureShopifyGid("Customer", customerShopifyId)
    )
  );
  const { addToast } = useToast();
  const [rewardEvents, setRewardEvents] = useState<IRewardEvent[]>([]);

  useEffect(() => {
    if (customer?.id) {
      typedFrontendVendorApi
        .getReq("/customer-relationship/:customerRelationshipId/reward-events", {
          params: {
            customerRelationshipId: customer.id,
          },
        })
        .then(res => {
          if (res.type === "success") {
            setRewardEvents(res.body);
          }
          if (res.type === "error") {
            console.error("Error fetching customer reward events");
          }
        })
        .catch(error => {
          console.error(`Error fetching customer reward events ${error}`);
          addToast("Error fetching customer reward events");
        });
    }
  }, [customer]);
  return rewardEvents;
};
